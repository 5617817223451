import axios from "@/axios";
import httpStatusCode from "http-status-codes";

export default {

  verifyAccountEmail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("onboarding/verifyEmailToken/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOnboardDetailWithId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("onboarding/getdetail/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchOnboardDetailWithIdWithoutToken({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("onboarding/getdetailforIdVerification/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveWebForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`onboarding/save`, data.data, data.config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  submitApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`onboarding/submitApplication`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`onboarding/update/${data._id}`, data.info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchOnboardingList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("onboarding/admin/adminList")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveSupportingFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
      .post("onboarding/saveSupportFile",payload.data, payload.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  /*Login related apis*/
  getOnboardRequestDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`onboarding/request-detail/${payload.introducerId}`)
        .then(res => {
          commit("SET_INTRODUCER_LOGO", res.data.data);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  createMyPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("onboarding/create-password", payload)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            localStorage.setItem(
              "onboardingUser",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem(
              "accessToken",
              response.data.data.tokens.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.tokens.refreshToken
            );
            localStorage.setItem(
              "tokenExpiry",
              response.data.data.tokens.accessTokenExpiresIn
            );
            localStorage.setItem("loggedIn", "true");

            commit("UPDATE_ONBOARDING_USER_INFO", response.data.data, { root: true });
            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response);
          }
          else {
            reject({ message: response.data.message });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  loginOnboardingUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("onboarding/login", payload)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            localStorage.setItem(
              "onboardingUser",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem(
              "accessToken",
              response.data.data.tokens.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response.data.data.tokens.refreshToken
            );
            localStorage.setItem(
              "tokenExpiry",
              response.data.data.tokens.accessTokenExpiresIn
            );
            localStorage.setItem("loggedIn", "true");

            commit("UPDATE_ONBOARDING_USER_INFO", response.data.data, { root: true });
            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  logoutOnboardingUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .delete("onboarding/logout")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchPaymentPlanDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/onboarding/payment-plan/${payload}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  forgotPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding/forgot-password`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },

  verifyResetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding/verify-reset-password/${data.token}`)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },

  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding/reset-password`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },

  verifyIDMatrix({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding/IdVerificationOfPerson/${data.id}/${data.type}/${data.verificationId}`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  removeBank({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding/removeBank/`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  deleteBankStatement({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding/deleteBankStatement/`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  updateTermsAndConditions({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding/updateTermsAndConditions/`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  getPayNowTerm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/legals/getTermsForOnboard/`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  getPayLaterTerm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`admin/payment-plans/getPlanById/`+ data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  removeTrustDeedDocument({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding/removeTrustDeedDocument/`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  saveAccountConfig({ commit }, data) {

    return new Promise((resolve, reject) => {
      axios
        .post(`/onboarding_new/saveAccountConfig/`, data.data,data.config)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    })
  },

  checkMerchantEmailValidity({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios
        .get(`onboarding/is-valid-merchant-email/${email}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendVerificationTokenEmail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `onboarding/sendVerificationCode`, payload
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  verifyCode({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("onboarding/verifyOtpCode", payload).then(response => {
        if (response.status == httpStatusCode.OK) {
          localStorage.setItem("onboardingUser", JSON.stringify(response.data.data));
          localStorage.setItem("accessToken", response.data.data.tokens.accessToken);
          localStorage.setItem("refreshToken", response.data.data.tokens.refreshToken);
          localStorage.setItem("tokenExpiry", response.data.data.tokens.accessTokenExpiresIn);
          localStorage.setItem("loggedIn", "true");

          commit("UPDATE_ONBOARDING_USER_INFO", response.data.data, { root: true });
          commit("SET_BEARER", response.data.data.accessToken);
          resolve(response);
        } else {
          reject({ message: response.data.message });
        }
      })
      .catch(ex => {
        reject(ex);
      });
    });
  },

  verifyidvToken ({ commit }, token){
    return new Promise((resolve, reject) => {
      axios
        .get(`onboarding/verifyidvToken/`,{headers: {'Authorization': token.idvToken}})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`settings/fetch/${payload}`).then(res => {
        resolve(res);
      }).catch(ex => {
        reject(ex);
      });
    });
  },

};
